import React, { useContext } from 'react';
import { AuthContext } from '../../Context/authContext';
import { Navigate } from 'react-router-dom';
import routes from '../../Constants/routes';
import { ROLES, PERMISSIONS } from '../../Constants/constant';

function KenPrivateRoute({ id, children, redirectRoute = routes.home }) {
  const {
    state: { isAuthenticated, user },
  } = useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to={routes.login} />;
  }

  const roleData = user?.role ? user?.role : 'APPLICANT';

  if (id && roleData && ROLES[roleData] && PERMISSIONS[roleData][id]) {
    return children;
  }
  return <Navigate to={redirectRoute} />;
}

export default KenPrivateRoute;

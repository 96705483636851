import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import KenPrivateRoute from '../../Components/KenPrivateRoute';
import routes from '../../Constants/routes';
import { AuthContext } from '../../Context/authContext';

import { useTheme } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import KenLoader from '../../Components/KenLoader';
import {
  PERMISSIONS,
  ROLES,
  TABLET_BREAKPOINT,
} from '../../Constants/constant';
import { useAppContext } from '../../Context/appContext';
import { ErrorFallback } from '../../Utils/ErrorBoundary';
import { getAxiosInstance, getConfig } from '../../Utils/apiServices';
const FeeStructure = React.lazy(() =>
  import('../FeePayment/Components/FeeStructure')
);
const FeeDetails = React.lazy(() =>
  import('../FeePayment/Components/FeeDetails')
);
const SideNavigation = React.lazy(() =>
  import('../../Components/SideNavigation')
);
const HomePage = React.lazy(() => import('../HomePage'));
const MenuAppBar = React.lazy(() => import('../../Components/MenuAppBar'));
const DocumentPage = React.lazy(() => import('../DocumentPage'));
const Login = React.lazy(() => import('../Auth/Login'));
const SignUp = React.lazy(() => import('../Auth/SignUp'));
const LoginOTP = React.lazy(() => import('../Auth/Login/LoginOTP'));
const VerifyNumber = React.lazy(() => import('../Auth/SignUp/VerifyNumber'));
const VerifyEmail = React.lazy(() => import('../Auth/SignUp/VerifyEmail'));
const VerifiedOTP = React.lazy(() => import('../Auth/SignUp/VerifiedOTP'));
const Courses = React.lazy(() => import('../Courses'));
const ApplicationForm = React.lazy(() => import('../ApplicationForm'));
const MyApplications = React.lazy(() => import('../MyApplications'));
const FeePayment = React.lazy(() => import('../FeePayment'));
const ApplicationFormNew = React.lazy(() =>
  import('../ApplicationForm/Application')
);
const OfferLetter = React.lazy(() => import('../OfferLetter'));
const OfferLetterDetailed = React.lazy(() =>
  import('../OfferLetter/Components/OfferLetterDetailed')
);
const ThankYouPage = React.lazy(() =>
  import('../../Components/KenApplicationForm/Components/ThankYouPage')
);
const OnboardQues = React.lazy(() =>
  import('../OfferLetter/Components/OnboardQues')
);
const InterviewSlots = React.lazy(() => import('../Interview'));
const Interview = React.lazy(() => import('../Interview/Interview'));

export default function MainLayout(props) {
  const [open, setOpen] = React.useState(true);
  const {
    dispatch: dispatchApp,
    state: { isAuthenticated, user, search },
  } = useContext(AuthContext);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down(TABLET_BREAKPOINT)
  );

  const {
    state: { isLoading = false },
    dispatch,
  } = useAppContext();

  const [config, setConfig] = useState();
  const location = useLocation();
  const [swipeableDrawer, setSwipeableDrawer] = useState();
  const isForm =
    location.pathname === '/form' || location.pathname === '/application';

  const [idle, setIdle] = useState(false);
  const handleOnIdle = () => {
    setIdle(true);
  };
  const timeout = 1000 * 60 * 10;

  // const methods = useIdleTimer({
  //   timeout,
  //   onIdle: handleOnIdle,
  // });

  useEffect(() => {
    if (idle === true) {
      dispatchApp({
        type: 'LOGOUT',
        payload: {
          user: {},
          token: null,
        },
      });
    }
  }, [idle]);

  React.useEffect(() => {
    getConfig()
      .then((res) => {
        dispatch({ type: 'updateConfig', value: res[0]?.config });
        getAxiosInstance(res);
        setConfig(res[0]?.config);
      })
      .catch((err) => {
        console.log('error in config', err);
      });
  }, []);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Suspense fallback={ErrorFallback}>
      <div style={!isForm ? { height: '100%' } : {}}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
            window.location.reload();
          }}
        >
          <SnackbarProvider autoHideDuration={5000}>
            <Box sx={{ display: 'flex', height: '100%' }}>
              <CssBaseline />
              {!config ? (
                <KenLoader />
              ) : (
                <>
                  {isLoading && <KenLoader />}
                  {isAuthenticated && !isForm && (
                    <>
                      <SideNavigation
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerOpen={handleDrawerOpen}
                        setSwipeableDrawer={setSwipeableDrawer}
                        swipeableDrawer={swipeableDrawer}
                        setOpen={setOpen}
                      />
                      <MenuAppBar
                        open={open}
                        handleDrawerOpen={handleDrawerOpen}
                        setSwipeableDrawer={setSwipeableDrawer}
                      />
                    </>
                  )}
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      margin: isMobileScreen
                        ? '0px'
                        : !isAuthenticated || isForm
                        ? '0px'
                        : screenSize
                        ? '80px - 28px'
                        : '80px 16px',
                      height: !isAuthenticated ? '100%' : 'calc(100% - 160px)',
                      width: isMobileScreen ? '100%' : undefined,
                      paddingTop: isMobileScreen ? '65px' : undefined,
                      //  Note: added following so for main screen the gird is splitted on 12 grid after subtracting side navbar
                      maxWidth:
                        !isMobileScreen && isAuthenticated && !isForm
                          ? open
                            ? 'calc(100% - 260px)'
                            : 'calc(100% - 145px)'
                          : undefined,
                    }}
                  >
                    <Routes>
                      <Route
                        path={'/form'}
                        element={
                          isAuthenticated ? (
                            <ApplicationForm />
                          ) : (
                            <Navigate to="/login" />
                          )
                        }
                      />
                      <Route
                        path={'/application'}
                        element={
                          isAuthenticated ? (
                            <ApplicationFormNew />
                          ) : (
                            <Navigate to="/login" />
                          )
                        }
                      />
                      <Route
                        path={'/'}
                        element={
                          search !== null &&
                          search !== '' &&
                          search !== undefined ? (
                            <Navigate to={`/courses?search=${search}`} />
                          ) : (
                            <Navigate to="/home" />
                          )
                        }
                      />
                      <Route
                        path={routes.login}
                        element={
                          isAuthenticated ? (
                            search !== null &&
                            search !== '' &&
                            search !== undefined ? (
                              <Navigate to={`/courses?search=${search}`} />
                            ) : (
                              <Navigate to="/home" />
                            )
                          ) : (
                            <Login />
                          )
                        }
                      />
                      <Route
                        path={routes.signup}
                        element={
                          isAuthenticated ? (
                            search !== null &&
                            search !== '' &&
                            search !== undefined ? (
                              <Navigate to={`/courses?search=${search}`} />
                            ) : (
                              <Navigate to="/home" />
                            )
                          ) : (
                            <SignUp />
                          )
                        }
                      />
                      <Route
                        path={routes.loginOTP}
                        element={
                          isAuthenticated ? (
                            search !== null &&
                            search !== '' &&
                            search !== undefined ? (
                              <Navigate to={`/courses?search=${search}`} />
                            ) : (
                              <Navigate to="/home" />
                            )
                          ) : (
                            <LoginOTP />
                          )
                        }
                      />
                      <Route
                        exact
                        path={`/feeStructure`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_FEESTRUCTURE}
                          >
                            <FeeStructure />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/offerLetter`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_OFFERLETTER}
                          >
                            <OfferLetter />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/interviewSlots`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_INTERVIEWSLOTS}
                          >
                            <InterviewSlots />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/interview`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_INTERVIEW}
                          >
                            <Interview />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/offerLetterDetailed`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={
                              PERMISSIONS[ROLES.APPLICANT]
                                .APP_OFFERLETTERDETAILED
                            }
                          >
                            <OfferLetterDetailed />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/feeDetails`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_FEEDETAILS}
                          >
                            <FeeDetails />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        path={routes.verifyNumber}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/home" />
                          ) : (
                            <VerifyNumber />
                          )
                        }
                      />
                      <Route
                        path={routes.verifyEmail}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/home" />
                          ) : (
                            <VerifyEmail />
                          )
                        }
                      />
                      <Route
                        path={routes.verifiedOTP}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/home" />
                          ) : (
                            <VerifiedOTP />
                          )
                        }
                      />
                      <Route
                        path={routes.newApplication}
                        element={
                          isAuthenticated ? (
                            <Navigate to="/home" />
                          ) : (
                            <ApplicationForm />
                          )
                        }
                      />
                      <Route
                        exact
                        path={`/home`}
                        redirectTo="/login"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_HOME}
                          >
                            <HomePage />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/document`}
                        redirectTo="/login"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_DOCUMENTS}
                          >
                            <DocumentPage />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/thankYou`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_THANKYOU}
                          >
                            <ThankYouPage />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        path={`/feePayments`}
                        redirectTo="/home"
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_FEEPAYEMENTS}
                          >
                            <FeePayment />
                          </KenPrivateRoute>
                        }
                      />

                      <Route
                        exact
                        path={`*`}
                        element={<Navigate to={routes.login} />}
                      />
                      <Route
                        exact
                        redirectTo={routes.login}
                        path={`/courses`}
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_COURSES}
                          >
                            <Courses />{' '}
                          </KenPrivateRoute>
                        }
                      />
                      {/* APPLICANT - MY APPLICATIONS */}
                      <Route
                        exact
                        // redirectTo={routes.login}
                        path={`/${routes.myApplications}`}
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_APPLICATIONS}
                          >
                            <MyApplications />
                          </KenPrivateRoute>
                        }
                      />
                      <Route
                        exact
                        redirectTo={`/${routes.home}`}
                        path={`/${routes.onboardQues}`}
                        element={
                          <KenPrivateRoute
                            id={PERMISSIONS[ROLES.APPLICANT].APP_ONBOARDING}
                          >
                            <OnboardQues />
                          </KenPrivateRoute>
                        }
                      />
                    </Routes>
                  </Box>
                </>
              )}
            </Box>
          </SnackbarProvider>
        </ErrorBoundary>
      </div>
    </Suspense>
  );
}
